const currencyList = [
  {value: 'usd', text: '$'},
  {value: 'rub', text: 'o'},
  {value: 'eur', text: '€'},
];

export const useCurrency = (curr: string) => {
  const currency = curr.toLowerCase();
  return currencyList.find((el) => el.value === currency)?.text || '';
};

export const useIsRub = (val: string) => val.toLowerCase() === 'rub';
